import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import { FormFields } from 'components';
import { AnimationFrame } from 'utils';
import { primaryTheme } from 'theming';

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '55px', md: 'unset' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
  },
});

const ComingText = chakra(Text, {
  baseStyle: {
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    textTransform: 'uppercase',
    fontSize: { base: '17px', xl: '24px' },
    fontWeight: '600',
    lineHeight: { base: '23px', xl: '26px' },
    maxWidth: { base: '231px', lg: '400px', xl: 'unset' },
  },
});
const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '32px', xl: '56px' },
    fontWeight: '400',
    lineHeight: { base: '37px', xl: '64px' },
    color: primaryTheme.colors.brand.white,
    maxWidth: { base: '300px', lg: '400px', xl: '754px', '4xl': '2200px' },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: { base: '16px', xl: '22px' },
    fontWeight: '300',
    lineHeight: { base: '24px', xl: '33px' },
    color: primaryTheme.colors.brand.white,
    maxWidth: { md: '50%', lg: '400px', xl: '635px', '3xlB': '2200px' },
  },
});

const FormComponent = () => {
  return (
    <Box>
      <Container>
        <Flex flexDirection="column" gap="27px">
          <ComingText animation={AnimationFrame.slideRight}>Yoga Joint Is Coming To West Boca!</ComingText>
          <Title animation={AnimationFrame.slideLeft}>
            Reserve <b>Your Lowest Rate</b> As A Founding Member Of Yoga Joint West Boca
          </Title>
          <SubTitle animation={AnimationFrame.slideRight}>
            Enter your information to <b>be 1 of the FIRST 200 new members to reserve your lowest rate</b> on membership, PLUS
            receive an exclusive Yoga Joint gift!
          </SubTitle>
          <FormFields />
        </Flex>
      </Container>
    </Box>
  );
};

export default FormComponent;
