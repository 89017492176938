import { Box } from '@chakra-ui/react';
import {
  SecondaryFooter,
  StudiosDescription,
  HeroSection,
  TransformSection,
  UniqueClasses,
  AppSection,
  PromoBar,
  LocationSection,
  TopBorder,
} from 'components';

const EveryOneIsWinnerPage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Box>
      <PromoBar isPromoClosed={false} onClick={scrollToTop} />
      <TopBorder />
      <HeroSection onClick={scrollToTop} />
      <TransformSection onClick={scrollToTop} />
      <StudiosDescription onClick={scrollToTop} />
      <UniqueClasses onClick={scrollToTop} />
      <AppSection onClick={scrollToTop} />
      <LocationSection />
      <SecondaryFooter />
    </Box>
  );
};

export default EveryOneIsWinnerPage;
