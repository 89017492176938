// Background Pattern Images
export const CareersBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CareersBg.png';
export const ShiftGroup1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ShiftGroup1.png';
export const PatternFourBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PatternFourBg.png';
export const PatternOneBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PatternOneBg.png';
export const PatternTwoBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PatternTwoBg.png';
export const ShiftIconOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ShiftIconOne.png';
export const ShiftIconSix = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ShiftIconSix.png';
export const ShiftIconTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ShiftIconTwo.png';
export const ShiftIconFive = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ShiftIconFive.png';
export const ShiftIconFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ShiftIconFour.png';
export const PatternThreeBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PatternThreeBg.png';
export const ShiftIconThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ShiftIconThree.png';
export const PatternOneInvertedBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PatternOneInvertedBg.png';
export const TransparentPlaceholder = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TransparentPlaceholder.png';
export const Ellipse = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Ellipse.png';

// Background Image used in home page for smooth fade out
export const FadeOutBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FadeOutBg.png';

// Download App images
export const Istore = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Istore.png';
export const Playstore = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Playstore.png';

// Icons
export const Star = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Star.png';
export const PlayIcon = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PlayIcon.png';
export const LocationIcon = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationIcon.png';
export const LocationMarker = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationMarker.png';

// Home Page - PageIntroduction
export const YogaPose = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/yogaPose.png';
export const YogaPoseMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaPoseMobile.png';

// HomePage -  LocationSegment
export const LocationOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationOne.png';
export const LocationSix = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSix.png';
export const LocationTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationTwo.png';
export const LocationFive = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFive.png';
export const LocationFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFour.png';
export const LocationEight = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationEight.png';
export const LocationSeven = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSeven.png';
export const LocationThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationThree.png';

// Home Page - ChannelNames
export const Journal1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Journal1.png';
export const Journal2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Journal2.png';
export const Journal3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Journal3.png';
export const Journal4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Journal4.png';

// Home Page - video thumbnail video image
export const HomePageThumbnailImage2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/HomePageThumbnailImage2.png';

// Membership Page
export const Member1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Member1.png';
export const Member2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Member2.png';
export const Member3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Member3.png';
export const Member4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Member4.png';
export const Member5 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Member5.png';
export const Member6 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Member6.png';
export const YogaPoseFitness = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaPoseFitness.png';
export const YogaPoseMembership = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaPoseMembership.png';
export const MembershipHeroImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MembershipHeroImage.png';

// Teachers Page Images
export const Teachers = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Teachers.png';
export const YogaGuru1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaGuru1.png';
export const YogaGuru2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaGuru2.png';
export const EmptyTeacher = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmptyTeacher.png';
export const WorkoutTypes = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WorkoutTypes.png';
export const YogaGurusPic = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaGurusPic.png';
export const YogaStickyGurus = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaStickyGurus.png';

// Coming Soon Page
export const Landing1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Landing1.png';

// Schedule Page
export const FiitOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FiitOne.jpg';
export const FiitTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FiitTwo.jpg';
export const ClassType1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassType1.png';
export const ClassType2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassType2.png';
export const ClassType3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassType3.png';
// Fiit Images
export const FiitFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FiitFour.jpg';
export const FiitThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FiitThree.jpg';
export const FiitOneMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FiitOneMobile.jpg';
export const FiitTwoMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FiitTwoMobile.jpg';
export const FiitThreeMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FiitThreeMobile.jpg';

// Special Workshop
export const SpecialWorkshopBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/SpecialWorkshopBg.png';

// Flow Images
export const FlowOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FlowOne.jpg';
export const FlowTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FlowTwo.jpg';
export const FlowFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FlowFour.jpg';
export const FlowThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FlowThree.jpg';
export const FlowOneMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FlowOneMobile.jpg';
export const FlowTwoMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FlowTwoMobile.jpg';
export const FlowThreeMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FlowThreeMobile.jpg';

// Restore Images
export const RestoreOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RestoreOne.jpg';
export const RestoreTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RestoreTwo.jpg';
export const RestoreFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RestoreFour.jpg';
export const RestoreThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RestoreThree.jpg';
export const RestoreOneMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RestoreOneMobile.jpg';
export const RestoreTwoMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RestoreTwoMobile.jpg';
export const RestoreThreeMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RestoreThreeMobile.jpg';

// Workshop Images
export const WorkshopOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WorkshopOne.jpg';
export const WorkshopTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WorkshopTwo.jpg';
export const WorkshopFive = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WorkshopFive.jpg';
export const WorkshopFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WorkshopFour.jpg';
export const WorkshopThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WorkshopThree.jpg';

// Testimonials Images
export const AvatarOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarOne.png';
export const AvatarSix = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarSix.png';
export const AvatarTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarTwo.png';
export const AvatarFive = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarFive.png';
export const AvatarFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarFour.png';
export const AvatarThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarThree.png';
export const AvatarSeven = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarSeven.png';
export const AvatarEight = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarEight.png';
export const AvatarNine = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarNine.png';
export const AvatarTen = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarTen.png';
export const AvatarEleven = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarEleven.png';
export const AvatarTwelve = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AvatarTwelve.png';

export const TestimonialBgOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgOne.png';
export const TestimonialBgSix = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgSix.png';
export const TestimonialBgTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgTwo.png';
export const TestimonialBgFive = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgFive.png';
export const TestimonialBgFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgFour.png';
export const TestimonialBgThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgThree.png';
export const TestimonialBgOneCropped = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgOneCropped.png';
export const TestimonialBgSixCropped = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgSixCropped.png';
export const TestimonialBgTwoCropped = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgTwoCropped.png';
export const TestimonialBgFiveCropped = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgFiveCropped.png';
export const TestimonialBgFourCropped = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgFourCropped.png';
export const TestimonialBgThreeCropped =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/TestimonialBgThreeCropped.png';

// Detailed Locations Images
export const AmandaC = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AmandaC.png';
export const JitiVoz = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/JitiVoz.png';
export const NoImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/NoImage.png';
export const MariaCee = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MariaCee.png';
export const Michelle = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/Michelle.png';
export const ClaraKohn = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClaraKohn.png';
export const AlikahBain = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AkilahBain.png';
export const JenHarless = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/JenHarless.png';
export const KatieLeeImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/KatieLee.png';
export const MarkKingImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MarkKing.png';
export const DebraHeslin = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/DebraHeslin.png';
export const MariaBoccia = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MariaBoccia.png';
export const MiguelAMora = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MiguelAMora.png';
export const TiffanyWard = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TiffanyWard.png';
export const WhitneyFair = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WhitneyFair.png';
export const KayleeBurke = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/KaylessBurke.png';
export const HellenOspina = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/HellenOspina.png';
export const LyndsayTubbs = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LyndsayTubbs.png';
export const MariaVecchio = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MariaVecchio.png';
export const MelissaNavia = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MelissaNavia.png';
export const RabeccaEunis = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RabeccaEunis.png';
export const RayWhetstone = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RayWhetstone.png';
export const SeanBaileyImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/SeanBailey.png';
export const DawnStaszesky = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/DawnStaszesky.png';
export const FlashMcLemore = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FlashMcLemore.png';
export const KatherineKnap = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/KatherineKnap.png';
export const KiyataBranker = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/KiyataBranker.png';
export const ShawndaBrooks = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ShawndaBrooks.png';
export const ChristelLorens = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ChristelLorens.png';
export const GabriellaVidak = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/GabriellaVidak.png';
export const LocationOneOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationOneOne.png';
export const LocationOneTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationOneTwo.jpg';
export const LocationSixOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSixOne.png';
export const LocationSixTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSixTwo.png';
export const LocationTwoOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationTwoOne.png';
export const LocationTwoTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationTwoTwo.png';
export const LuisAndKatilyn = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LuisAndKaitlyn.png';
export const MindsterBehnke = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MindsterBehnke.png';
export const LeaderImageOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudioLeaderOne.jpg';
export const LeaderImageTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudioLeaderTwo.jpg';
export const LeaderImageNine = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudioLeaderNine.png';
export const AnthonyMarnique = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AnthonyMarnique.png';
export const CarolinaMustafa = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CarolinaMustafa.png';
export const FranciscoFuster = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FranciscoFuster.png';
export const LeaderImageFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LeaderImageFour.png';
export const LocationFiveOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFiveOne.png';
export const LocationFiveTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFiveTwo.png';
export const LocationFourOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFourOne.png';
export const LocationFourTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFourTwo.png';
export const LocationOneFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationOneFour.png';
export const LocationSixFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSixFour.png';
export const LocationTwoFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationTwoFour.png';
export const LocationTwoTwo2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationTwoTwo2.png';
export const LeaderImageFive = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudioLeaderFive.png';
export const ClaudiaSotolongo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClaudiaSotolongo.png';
export const LocationEightOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationEightOne.png';
export const LocationEightTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationEightTwo.png';
export const LocationFiveFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFiveFour.png';
export const LocationFourFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFourFour.png';
export const LocationOneThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationOneThree.png';
export const LocationSevenOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSevenOne.png';
export const LocationSevenTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSevenTwo.png';
export const LocationSixThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSixThree.png';
export const LocationThreeOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationThreeOne.png';
export const LocationThreeTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationThreeTwo.png';
export const LocationTwoThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationTwoThree.png';
export const LeaderImageSeven = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudioLeaderSeven.jpg';
export const LeaderImageEight = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudioLeaderEight.png';
export const LeaderImageThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudioLeaderThree.png';
export const LocationEightFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationEightFour.png';
export const LocationFiveThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFiveThree.png';
export const LocationFourThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationFourThree.png';
export const LocationSevenFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSevenFour.png';
export const LocationThreeFour = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationThreeFour.png';
export const YuliaKonovnitsyna = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YuliaKonovnitsyna.png';
export const ElizabethHonewell = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ElizabethHoneywell.png';
export const IanCombrinckGraham = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IanCombrinckGraham.png';
export const LocationEightThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationEightThree.png';
export const LocationSevenThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationSevenThree.png';
export const LocationThreeThree = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LocationThreeThree.png';
export const AdenFerminReviewCardImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AdenFerminReview.png';

// Primary Footer Images
export const AppPreview = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/appPreview.png';
export const FooterLogo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FooterLogo.png';
export const LogoBlackImg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/LogoBlackImg.png';

// LocationsPage Images
export const YogaCleaning = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaCleaning.png';
export const YogaLocation1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation1.png';
export const YogaLocation2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation2.png';
export const YogaLocation3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation3.png';
export const YogaLocation4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation4.png';
export const YogaLocation5 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation5.png';
export const YogaLocation6 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation6.png';
export const YogaLocation7 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation7.png';
export const YogaLocation8 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation8.png';
export const YogaLocation9 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation9.png';
export const YogaLocation10 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation10.png';
export const YogaLocation11 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation11.png';
export const YogaLocation12 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation12.png';
export const YogaLocation12Large = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation12Large.png';

export const StudioLocations = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudioLocations.png';
export const YogaLocation7Two = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaLocation7Two.png';

// CareersPage
export const CareersBanner = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CareersBanner.png';

// Download Page
export const MobileAppScreen = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MobileAppScreen.png';
export const DownloadAppBackground = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/DownloadAppBackground.png';
export const DownloadAppBackgroundMobile =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/DownloadAppBackgroundMobile.png';

// Classes page
export const HotSpot1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/HotSpot1.png';
export const HotSpot2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/HotSpot2.png';
export const HotSpot3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/HotSpot3.png';
export const HotSpot4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/HotSpot4.png';
export const HotSpot5 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/HotSpot5.png';
export const ClassStudio1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassStudio1.png';
export const ClassStudio2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassStudio2.png';
export const ClassStudio3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassStudio3.png';
export const ClassStudio4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassStudio4.png';
export const ClassStudio5 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassStudio5.png';
export const ClassesHeroImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassesHeroImage.png';
export const ClassesHeroImageMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ClassesHeroImageMobile.png';

// On Demand Page
export const OnDemandImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/OnDemandImage.png';
export const OnDemandMobileBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/OnDemandMobileBg.png';
export const OnDemandDesktopBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/OnDemandDesktopBg.jpg';

// Your First Class Page
export const FirstClassBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FirstClassBg.jpg';
export const FirstClassBg1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FirstClassBg1.png';
export const FirstClassBgMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FirstClassBgCropped.jpg';
export const FirstClassVideoThumbnail = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/FirstClassVideoThumbnail.png';

// Openings Page Images
export const OpeningsBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/OpeningsBg.png';
export const OpeningsBgMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/OpeningsBgMobile.png';

// UpcomingLocation Page Images
export const UpcomingLocationsBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UpcomingLocationsBg.png';
export const UpcomingBenefitsImage1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UpcomingBenefitsImage1.png';
export const UpcomingBenefitsImage2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UpcomingBenefitsImage2.png';
export const UpcomingBenefitsImage3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UpcomingBenefitsImage3.png';
export const UpcomingBenefitsImage4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UpcomingBenefitsImage4.png';
export const UpcomingBenefitsImage5 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UpcomingBenefitsImage5.png';
export const UpcomingLocationsMobileBg =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/UpcomingLocationsMobileBg.png';

// Thankyou Page
export const ThankYouImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThankYouImage.png';
export const ThankyouPageAppBG = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThankyouPageAppBG.png';

// Retreats Page
export const RetreatsBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatsBg.jpg';
export const RetreatsMobileBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatsMobileBg.png';

export const RetreatImage1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatImage1.png';
export const RetreatImage2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatImage2.png';
export const RetreatImage3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatImage3.png';
export const RetreatImage4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatImage4.png';

export const TripLocation1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TripLocation1.png';
export const TripLocation2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TripLocation2.png';
export const TripLocation3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TripLocation3.png';
export const TripLocation4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TripLocation4.png';
export const TripLocation5 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TripLocation5.png';

export const CommingSoon10 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CommingSoon10.png';
export const RetreatModal = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatModal.png';
export const RetreatModalLocation = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatModalLocation.png';

// Retreat Thankyou Page
export const ThankYouRetreatHeroImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThankYouRetreatHeroImage.png';
export const ThankYouRetreatCoverImage =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThankYouRetreatCoverImage.png';

// PromoBar
export const PromoBarImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PromoBarImage.png';
export const RetreatsModalBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatsModalBg.png';
export const PromoModalImage1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PromoModalImage1.png';
export const PromoModalImage2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PromoModalImage2.png';
export const ReferralPromoImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ReferralPromoImage.png';
export const RetreatsModalImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatsModalImage.png';
export const PromoBarImageMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PromoBarImageMobile.png';
export const PromoBarImageFull = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PromoBarImageFull.png';

// Empower Series
export const JohnMacleod = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/JohnMacleod.png';
export const DavidCampbell = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/DavidCampbell.png';
export const CaitlinMorris = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CaitlinMorris.png';
export const MasterClassImage1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MasterClassImage1.png';
export const MasterClassImage2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MasterClassImage2.png';
export const MasterClassImage3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MasterClassImage3.png';
export const MasterClassImage4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MasterClassImage4.png';
export const EmpowerSeriesCut1Desktop = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesCut1Desktop.png';
export const EmpowerSeriesCut2Desktop = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesCut2Desktop.png';
export const EmpowerSeriesCut3Desktop = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesCut3Desktop.png';
export const EmpowerSeriesImage1Mobile =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage1Mobile.png';
export const EmpowerSeriesImage2Mobile =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage2Mobile.png';
export const EmpowerSeriesImage3Mobile =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage3Mobile.png';
export const EmpowerSeriesImage5Mobile =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage5Mobile.png';
export const EmpowerSeriesImage1Desktop =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage1Desktop.png';
export const EmpowerSeriesImage2Desktop =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage2Desktop.png';
export const EmpowerSeriesImage3Desktop =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage3Desktop.png';
export const EmpowerSeriesImage4Desktop =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage4Desktop.png';
export const EmpowerSeriesImage5Desktop =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/EmpowerSeriesImage5Desktop.png';

// Modal
export const CaboModalImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CaboModalImage.png';
export const TulumModalImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TulumModalImage.png';
export const CaboModalMobileBG = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CaboModalMobileBG.png';
export const WorkshopsModalBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WorkshopsModalBg.png';
export const ReferralPromoBGImage = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ReferralPromoBGImage.png';
export const RetreatsModalInfoImage1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatsModalInfoImage1.png';
export const RetreatsModalInfoImage2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/RetreatsModalInfoImage2.png';
export const ComingSoonModalHeader = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ComingSoonModalHeader.png';

// Banner Background
export const BgEmpowerBannerDesktop = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/BgEmpowerBannerDesktop.png';
export const BgEmpowerBannerMobile = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/BgEmpowerBannerMobile.png';

// Teachers Training
export const TeachersTrainingHero = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TeachersTrainingHero.png';
export const TeachersTrainingVideoThumbnailMob =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/TeachersTrainingVideoThumbnailMob.png';
export const TrachersTrainingcertificate =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/TrachersTrainingcertificate.png';
export const TrachersTrainingcertificate2 =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/TrachersTrainingcertificate2.png';

export const TeachersChelley = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TeachersChelley.png';
export const TeachersHalley = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TeachersHalley.png';
export const TeachersJeff = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TeachersJeff.png';
export const TeachersJohn = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/TeachersJohn.png';
export const BackgroundFive = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/BackgroundFive.png';
export const BackgroundFiveMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/BackgroundFiveMob.png';
export const BackgroundOne = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/BackgroundOne.png';
export const GroupClasses = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/GroupClasses.png';

// Three For free
export const ThreeForFreeHero = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThreeForFreeHero.png';
export const ThreeForFreeHeroMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThreeForFreeHeroMob.png';
export const BackGroundTwo = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/BackGroundTwo.png';
export const ThreeForFreeBgMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThreeForFreeBgMob.png';

export const ThreeForFreeFitness = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThreeForFreeFitness.png';
export const ThreeForFreeFitnessMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThreeForFreeFitnessMob.png';
export const IconInjury = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IconInjury.png';
export const IconMotion = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IconMotion.png';
export const IconRespiratory = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IconRespiratory.png';
export const IconFlexibility = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IconFlexibility.png';
export const IconHeart = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IconHeart.png';
export const IconEndurance = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IconEndurance.png';
export const IconStrength = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IconStrength.png';
export const IconMuscle = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/IconMuscle.png';

export const YogaJointMethod1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaJointMethod1.png';
export const YogaJointMethod2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaJointMethod2.png';
export const YogaJointMethod3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaJointMethod3.png';
export const YogaJointMethod4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaJointMethod4.png';
export const YogaJointMethod5 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaJointMethod5.png';
export const MobileAppScreens = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/MobileAppScreens.png';

// Pre Sale
export const PreSaleHero = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PreSaleHero.png';

export const PackageIcon11 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon11.png';
export const PackageIcon12 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon12.png';
export const PackageIcon13 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon13.png';
export const PackageIcon14 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon14.png';
export const PackageIcon15 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon15.png';
export const PackageIcon16 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon16.png';

export const PackageIcon21 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon21.png';
export const PackageIcon22 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon22.png';
export const PackageIcon23 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon23.png';
export const PackageIcon24 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon24.png';
export const PackageIcon25 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon25.png';

export const PackageIcon31 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon31.png';
export const PackageIcon32 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon32.png';
export const PackageIcon33 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PackageIcon33.png';
export const BackgroundSeven = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/BackgroundSeven.png';
export const WhatIsYogaJoint = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WhatIsYogaJoint.png';

export const PreSaleRegisteredHero = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PreSaleRegisteredHero.png';
export const PreSaleNextStepHero = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PreSaleNextStepHero.png';
export const BellIcon = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/BellIcon.png';
export const PlayScreenIcon = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/PlayScreenIcon.png';
export const ScheduleIcon = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ScheduleIcon.png';

// Give Away Winners
export const WinnersHero = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WinnersHero.png';
export const WinnersHeroMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WinnersHeroMob.png';
export const DiamondPackageWinner = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/DiamondPackageWinner.png';
export const GoldPackageWinner1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/GoldPackageWinner1.png';
export const GoldPackageWinner2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/GoldPackageWinner2.png';
export const GoldPackageWinner3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/GoldPackageWinner3.png';

export const YjLogoWithBg = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YjLogoWithBg.png';

export const StudiosDescription1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudiosDescription1.png';
export const StudiosDescription2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudiosDescription2.png';
export const StudiosDescription3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudiosDescription3.png';
export const StudiosDescription4 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/StudiosDescription4.png';

export const UniqueClasses1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UniqueClasses1.png';
export const UniqueClasses2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UniqueClasses2.png';
export const UniqueClasses2Mob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/UniqueClasses2Mob.png';

export const YogaAppSpec1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaAppSpec1.png';
export const YogaAppSpec2 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaAppSpec2.png';
export const YogaAppSpec3 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/YogaAppSpec3.png';
export const CoralSpringMapStudio = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CoralSpringMapStudio.png';
export const CoralSpringMapStudioMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CoralSpringMapStudioMob.png';
export const CoralSpringRegisterHero = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/CoralSpringRegisterHero.png';
export const CoralSpringRegisterHeroMob =
  'https://yoga-joint-website.s3.amazonaws.com/assets/images/CoralSpringRegisterHeroMob.png';
export const ThankYouImage0 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThankYouImage0.png';
export const ThankYouImage1 = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/ThankYouImage1.png';
export const AppScreens = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/AppScreens.png';

// WestBoca Pre sale
export const WestBocaMapStudio = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WestBocaMapStudio.png';
export const WestBocaMapStudioMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WestBocaMapStudioMob.png';
export const WestBocaSwag = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WestBocaSwag.png';
export const WestBocaSwagMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WestBocaSwagMob.png';
export const WestBocaFormHero = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WestBocaFormHero.png';
export const WestBocaFormHeroMob = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WestBocaFormHeroMob.png';
export const WestBocaFormHeroLarge = 'https://yoga-joint-website.s3.amazonaws.com/assets/images/WestBocaFormHeroLarge.png';
