import { Box } from '@chakra-ui/react';
import { SecondaryFooter, TopBorder, GetAppHeroSection } from 'components';

const GetAppPage = () => {
  return (
    <Box>
      <TopBorder />
      <GetAppHeroSection />
      <SecondaryFooter isRegisterPage />
    </Box>
  );
};

export default GetAppPage;
