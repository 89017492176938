import { Box } from '@chakra-ui/react';
import { SecondaryFooter, TopBorder, LocationSection, SwagSection, FormHeroSection } from 'components';
import { useRef } from 'react';

const FormPage = () => {
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    formRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  return (
    <Box>
      <TopBorder />
      <Box ref={formRef}>
        <FormHeroSection />
      </Box>
      <SwagSection onClick={scrollToForm} />
      <LocationSection />
      <SecondaryFooter />
    </Box>
  );
};

export default FormPage;
