import { Box } from '@chakra-ui/react';

const PlayStoreIcon = () => {
  return (
    <Box as="svg" width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.165819 1.66706C0.0590012 1.90936 0 2.20614 0 2.55129V22.5963C0 22.9419 0.0589763 23.2385 0.165751 23.4806L11.0718 12.573L0.165819 1.66706ZM0.79485 24.1333C1.19932 24.307 1.72728 24.2524 2.30305 23.9259L15.1337 16.6349L11.7127 13.2139L0.79485 24.1333ZM15.9516 16.1711L19.9779 13.8835C21.2441 13.1629 21.2441 11.9856 19.9779 11.2659L15.9492 8.97671L12.3535 12.573L15.9516 16.1711ZM15.1319 8.51228L2.30305 1.22257C1.72753 0.89553 1.19979 0.841155 0.795379 1.01484L11.7126 11.9321L15.1319 8.51228Z"
        fill="black"
      />
    </Box>
  );
};

export default PlayStoreIcon;
