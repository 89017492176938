import { Box, chakra, Flex, useMediaQuery, Button, AspectRatio, Text } from '@chakra-ui/react';
import {
  BackGroundTwo,
  ShiftIconFive,
  ShiftIconOne,
  ShiftIconSix,
  ThreeForFreeBgMob,
  YogaJointLogoWithTextSmall,
  ShiftIconTwo,
  Ellipse,
} from 'assets';
import { TextContentColumn } from 'components';
import { AnimationFrame, Constant, hasNativeHLS } from 'utils';
import ReactPlayer from 'react-player';
import { useRef } from 'react';
import { primaryTheme } from 'theming';
import { Link } from 'react-router-dom';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      md: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconOne}) ,url(${ShiftIconTwo}), url(${ShiftIconOne})`,
      lg: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconOne}), url(${ShiftIconFive}),url(${ShiftIconFive}),url(${ShiftIconTwo}),url(${ShiftIconOne}), url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: {
      md: '150% 0%, 130% 100%, 0% 0%, 0% 0% !important',
      lg: '100% 50%, 90% -20%, 90% 20%, 0% 80%, 10% -10%, 0% -90%, 10% 20%, 50% -10% !important',
      '3xlB': '100% 50%, 90% -20%, 90% 20%, 17% 70%, 10% -20%, 0% -100%, 15% 30%, 50% -30% !important',
    },
  },
});

const InnerContainer = chakra(Box, {
  baseStyle: {
    background: {
      base: 'linear-gradient(180deg, #181c43 0%, rgba(35, 41, 96, 0) 20%)',
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'contain', md: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    paddingTop: { base: '30px', lg: 'unset' },
    background: { base: `url(${ThreeForFreeBgMob})`, md: 'none' },
    gap: { lg: '35px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
  },
});

type HeroSectionProps = {
  onClick?: () => void;
};

const HeroSection = ({ onClick }: HeroSectionProps) => {
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const videoRef = useRef<ReactPlayer>(null);
  return (
    <OuterContainer>
      <InnerContainer>
        <Box background={`url(${Ellipse})`} bgRepeat="no-repeat" backgroundSize="contain" paddingTop={{ lg: '38px' }}>
          {!isMobileView && (
            <Box margin="0 0 30px 100px">
              <YogaJointLogoWithTextSmall />
            </Box>
          )}
          <Container paddingBottom="155px">
            {isMobileView && (
              <Box margin="0 0 28px 0">
                <YogaJointLogoWithTextSmall />
              </Box>
            )}
            <TextContentColumn
              title={'The Grand Opening Of Our West Boca Studio Is Here!'}
              description={
                isMobileView
                  ? `Be 1 of the <strong>FIRST 200 New Members</strong> to<br/>Reserve Your Lowest Rate!`
                  : `Be 1 of the <strong>FIRST 200 New Members</strong> to Reserve Your Lowest Rate!`
              }
            />
            <Text
              textAlign={{ base: 'center' }}
              marginTop={{ base: '20px', lg: '-30px' }}
              fontWeight={{ base: '500', lg: '400' }}
              fontSize={{ base: '18px', lg: '30px' }}
              color={primaryTheme.colors.brand.white}
              animation={AnimationFrame.fadeOut}
              whiteSpace="pre-line"
            >
              {isMobileView ? `Plus Receive An Exclusive\nYoga Joint Gift!` : `Plus Receive An Exclusive Yoga Joint Gift!`}
            </Text>
            <AspectRatio
              ratio={16 / 9}
              maxW={{ base: '100vw', lg: '55vw', '3xlB': '100vw' }}
              maxH={{ xl: '55vh', '3xlB': '100vw' }}
              width="100%"
              height="100%"
              overflow="hidden"
            >
              <Box position="relative" margin={{ base: '20px 0 30px', lg: '0 0 10px' }}>
                <ReactPlayer
                  muted
                  playing
                  loop
                  ref={videoRef}
                  controls
                  width="100%"
                  height="100%"
                  url="https://d2m1fy87s5v0ss.cloudfront.net/VIDEOS/AppleHLS1/Studio%20Drone%20Tour%20v5.m3u8"
                  config={{
                    file: {
                      forceHLS: !hasNativeHLS(),
                      forceVideo: true,
                      hlsVersion: '1.3.0',
                      attributes: {
                        autoPlay: true,
                        playsInline: true,
                        controlsList: 'fullscreen',
                        disablePictureInPicture: true,
                        style: {
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        },
                      },
                    },
                  }}
                />
              </Box>
            </AspectRatio>
            <Link to="/form" onClick={onClick}>
              <Button
                variant="primary"
                size="primary"
                width={{ base: window.screen.width - 40, md: '446px' }}
                height="56px"
                _focus={{ outline: '0 !important' }}
                fontWeight="700"
                fontSize="16px"
                margin={{ base: '27px auto 0', xl: '10px 0 0' }}
                animation={AnimationFrame.fadeOut}
                cursor="pointer"
                alignSelf="center"
                display="flex"
                whiteSpace="pre-line"
              >
                {Constant.CTA_TEXT_DESKTOP}
              </Button>
            </Link>
          </Container>
        </Box>
      </InnerContainer>
    </OuterContainer>
  );
};

export default HeroSection;
