import { Box, chakra, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { BackGroundTwo, ShiftIconOne, ShiftIconSix, ShiftIconTwo } from 'assets';
import { LocationImageComp } from 'components';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, useNavBarDimensions } from 'utils';

const InnerContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    paddingTop: { base: '50px', md: '80px', lg: '218px', xl: '308px' },
    alignItems: 'center',
    justifyContent: 'center',
    background: { lg: `rgba(0, 0, 0, 0.2) url(${BackGroundTwo})` },
    backgroundSize: { base: '100% 190%', lg: '100% 100%' },
    backgroundRepeat: 'no-repeat !important',
  },
});
const Container = chakra(Box, {
  baseStyle: {
    background: {
      base: `
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${ShiftIconOne}), url(${ShiftIconSix}),url(${ShiftIconTwo}), url(${ShiftIconTwo})`,
    },
    backgroundPosition: {
      base: '20% 100%, 0% 0% , 0% 0%, 110% 30% !important',
      '3xlB': '20% 100%, 10% 0% , 5% 40%, 100% 30% !important',
    },
    backgroundRepeat: 'no-repeat !important',
  },
});
const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '30px', lg: '40px' },
    lineHeight: { base: '40px', lg: '110%' },
    textAlign: 'center',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    whiteSpace: 'pre-line',
    margin: 'auto',
    maxWidth: { base: '100%', lg: '884px' },
    fontWeight: '500',
  },
});

const LocationSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const { margin } = useNavBarDimensions();

  return (
    <Container>
      <InnerContainer
        paddingLeft={!isMobileView ? margin : '20px'}
        paddingRight={!isMobileView ? margin : '20px'}
        margin="auto"
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
      >
        <Flex flexDirection="column" maxWidth="1240px" gap={{ base: '57px', md: '70px', lg: '101px' }}>
          <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
            We look forward to welcoming you to our gorgeous new West Boca studio!
          </Title>
          <LocationImageComp />
        </Flex>
      </InnerContainer>
    </Container>
  );
};

export default LocationSection;
