import { primaryTheme } from 'theming';
import { TransparentPlaceholder } from '../../assets';
import { Box, chakra, Flex, Text, Image as ChakraImage, useMediaQuery } from '@chakra-ui/react';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { AnimationFrame } from 'utils';

const Image = chakra(ChakraImage, {
  baseStyle: {
    width: '100%',
  },
});

const Card = chakra(Box, {
  baseStyle: {
    marginBottom: '32px',
    height: 'fit-content',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontWeight: '300',
    lineHeight: '29px%',
    fontSize: { base: '17px', md: '20px' },
    color: primaryTheme.colors.brand.white,
    marginBottom: '20px',
    whiteSpace: 'pre-line',
    maxWidth: '490px',
  },
});

const CustomText = chakra(Text, {
  baseStyle: {
    zIndex: '1',
    fontSize: { base: '220px', lg: '284px' },
    position: 'absolute',
    opacity: '15%',
    fontWeight: '600',
    color: '#fff',
    transform: { md: 'rotate(270deg)' },
    whiteSpace: 'nowrap',
  },
});

const CustomTextSmall = chakra(Text, {
  baseStyle: {
    transform: { md: 'rotate(270deg)' },
    whiteSpace: 'nowrap',
    zIndex: '2',
    fontSize: { base: '112px', lg: '170px' },
    position: 'absolute',
    fontWeight: '600',
    color: '#fff',
  },
});

interface CardContent {
  id: number;
  title: string;
  image: string;
  description: string;
  classType: string;
  mobImage: string;
}

interface ClassCardProps {
  index: number;
  animate?: string;
  item: CardContent;
}

const ClassCard = ({ index, item, animate }: ClassCardProps) => {
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  const isSecond = () => {
    return (index - 1) % 2 === 0;
  };

  const getMarginLeft = () => {
    if (index % 2 === 0) {
      return '0px';
    }
    return '73px';
  };

  const getMarginRight = () => {
    if (index % 2 === 1) {
      return '0px';
    }
    return '32px';
  };

  const getMarginTop = () => {
    if (index % 2 === 0) {
      return '-100px';
    }
    return '0';
  };
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.2 });

  return (
    <Card
      width="unset"
      animation={animate}
      key={item.id}
      marginRight={{ base: 0, md: '16px', xl: getMarginRight() }}
      marginLeft={{ base: 0, md: '16px', xl: getMarginLeft() }}
      marginBottom={{ base: '40px', md: '125px' }}
      marginTop={{ base: '40px', md: getMarginTop(), xl: !isSecond() ? '-100px' : '0px' }}
      height="100%"
      ref={ref}
      opacity={enterCount >= 1 ? 1 : 0}
    >
      <Box position="relative">
        <Image
          maxHeight="577px"
          transitionDuration="300ms"
          src={isMobileView ? item.mobImage : item.image}
          fallbackSrc={TransparentPlaceholder}
        />
        <CustomText
          left={{ base: '10%', md: isSecond() ? '-45%' : '-12%' }}
          bottom={{ base: '-22%', md: isSecond() ? '4%' : '-4%' }}
          animation={{
            base: inViewport || enterCount ? AnimationFrame.SlideRightWithOpacity : 'none',
            md: inViewport || enterCount ? AnimationFrame.SlideUpWithOpacity : 'none',
          }}
        >
          {item.classType}
        </CustomText>
        <CustomTextSmall
          left={{ base: '2%', md: isSecond() ? '-37%' : '-16%' }}
          bottom={{ base: '-20%', md: isSecond() ? '3%' : '-16.5%' }}
          animation={{
            base: inViewport || enterCount ? AnimationFrame.slideRight : 'none',
            md: inViewport || enterCount ? AnimationFrame.SlideUp270 : 'none',
          }}
        >
          {item.classType}
        </CustomTextSmall>
      </Box>

      <Flex
        flexDirection="column"
        justifyContent="flex-end"
        height={{ base: 'auto' }}
        padding={{ base: '40px 10px 0px 32px', md: '40px 25px 0px 40px' }}
        marginTop={{ base: '50px', md: !isSecond() ? '100px' : '124px', lg: !isSecond() ? '194px' : '224px' }}
      >
        <Title>
          <strong>{item.title}</strong>
        </Title>
        <Title>{item.description}</Title>
      </Flex>
    </Card>
  );
};

export default ClassCard;
