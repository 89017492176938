import { Box, chakra, Flex, Image, useMediaQuery } from '@chakra-ui/react';
import {
  BackGroundTwo,
  YogaJointLogoWithTextSmall,
  ShiftIconFive,
  ShiftIconOne,
  ShiftIconSix,
  ShiftIconTwo,
  Ellipse,
  WestBocaFormHero,
  WestBocaFormHeroMob,
  WestBocaFormHeroLarge,
} from 'assets';
import { AnimationFrame, useNavBarDimensions } from 'utils';
import { FormComponent } from '.';
const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      lg: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${ShiftIconSix}),url(${ShiftIconFive}),url(${ShiftIconTwo}), url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: {
      lg: '132% 65%, 93% -5%, 80% 90%, 120% 80% !important',
      xl: '127% 38%, 90% -5%, 75% 100%, 110% 80% !important',
      '3xlB': '100% 100%, 100% 0%, 80% 30%, 85% 70% !important',
    },
  },
});

const InnerContainer = chakra(Box, {
  baseStyle: {
    background: {
      base: 'linear-gradient(180deg, #181c43 0%, rgba(35, 41, 96, 0) 20%)',
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'contain', md: '100% 30%' },
    backgroundRepeat: 'no-repeat !important',
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: { base: '8px', md: '20px' },
    maxWidth: { '3xlB': '2000px' },
  },
});

const HeroSection = () => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const isSmallScreen = window.screen.width <= 1000;
  const isLargeScreen = window.screen.width >= 2000;

  return (
    <OuterContainer>
      <InnerContainer>
        <Box
          justifyContent="flex-end"
          background={`url(${Ellipse})`}
          bgRepeat="no-repeat"
          backgroundSize="contain"
          paddingTop={{ lg: '62px' }}
        >
          {!isMobileView && (
            <Box margin={{ md: '40px', lg: '0 0 0 100px' }}>
              <YogaJointLogoWithTextSmall />
            </Box>
          )}
          <Flex
            paddingLeft={margin}
            paddingRight={isSmallScreen ? margin : ''}
            width="100%"
            alignItems="flex-end"
            justifyContent="space-between"
            marginTop={{ xl: '-20px' }}
            background={{ base: `url(${WestBocaFormHeroMob})`, lg: 'none' }}
            paddingTop={{ base: '30px', md: 'unset' }}
            backgroundSize={{ base: '50%', lg: 'unset' }}
            backgroundRepeat="no-repeat !important"
            backgroundPosition={{ base: 'top right' }}
            gap={{ lg: '100px' }}
            maxWidth={{ '3xlB': '3500px' }}
            marginLeft={{ '3xlB': 'auto' }}
            alignSelf="flex-end"
          >
            <LeftContainer>
              {isMobileView && (
                <Box margin={{ md: '40px', lg: '0 0 0 100px' }}>
                  <YogaJointLogoWithTextSmall />
                </Box>
              )}
              <FormComponent />
            </LeftContainer>
            {!isMobileView && (
              <Image
                animation={AnimationFrame.fadeOut}
                src={isLargeScreen ? WestBocaFormHeroLarge : WestBocaFormHero}
                objectFit={{ lg: 'cover' }}
                maxWidth={{ lg: '400px', xl: '570px', '2xl': '670px', '3xlB': '1000px', '4xl': '2000px' }}
                alignSelf="right"
                height={{ lg: '850px', xl: '1003px' }}
              />
            )}
          </Flex>
        </Box>
      </InnerContainer>
    </OuterContainer>
  );
};

export default HeroSection;
