import {
  YogaJointMethod2,
  YogaJointMethod5,
  StudiosDescription1,
  StudiosDescription2,
  StudiosDescription3,
  StudiosDescription4,
  UniqueClasses1,
  UniqueClasses2,
  YogaAppSpec1,
  YogaAppSpec2,
  YogaAppSpec3,
  UniqueClasses2Mob,
} from './images';

const StudiosDescriptionList = [
  {
    id: 1,
    name: `Special\n flooring`,
    description: `Our double-layered rubber flooring is ultra-hygienic, moisture-resistant, reduces slippage, and cushions the joints by absorbing tension.`,
    image: StudiosDescription1,
  },
  {
    id: 2,
    name: 'Spa-inspired change rooms',
    description: `Complimentary lockers and fully-equipped change rooms with high-end bath products ensure you leave our studios feeling brand new.`,
    image: StudiosDescription2,
  },
  {
    id: 3,
    name: 'State-of-the-art sound',
    description: `A specialized audio and microphone system ensures that you hear your teacher’s every word crisp and clear — even when the bass is thumping.`,
    image: StudiosDescription3,
  },
  {
    id: 4,
    name: `Infrared\nheat`,
    description: `Our infrared heat system is the best on the market and penetrates the skin to warm up the muscles and reduce inflammation.`,
    image: YogaJointMethod2,
  },
  {
    id: 5,
    name: 'Complimentary yoga mat & towel',
    description: `We know how cumbersome it can be to drag your yoga mat and towel around, so we’ve saved you the trip! All members enjoy a complimentary yoga mat and towel for class`,
    image: StudiosDescription4,
  },
  {
    id: 6,
    name: 'Reserve your favorite mat space',
    description: `A vinyasa just isn’t the same if you’re not in your favorite spot. That’s why our Yoga Joint app allows you to reserve your fave mat space ahead of time.`,
    image: YogaJointMethod5,
  },
];

const UniqueClassesList = [
  {
    id: 1,
    title: 'Test the limits of your endurance with FIIT, our heated HIIT-inspired cardio-crushing classes rooted in yoga.',
    description: `A high-intensity HIIT-inspired class, FIIT focuses on building strength, cardiovascular health, and improving reflexes and coordination.\n\n In FIIT, you’re moving too quickly to think about much else, which, in its own way, gives the mind time to rest and reset while the body is in motion. During this class, your body will break down muscle to rebuild, leading to faster, more dramatic results.`,
    image: UniqueClasses1,
    mobImage: UniqueClasses1,
    classType: 'FIIT',
  },
  {
    id: 2,
    title: 'Root yourself in traditional techniques with Flow, our uniquely branded, vinyasa-inspired hot yoga.',
    description: `Based on our unique “5-Flow” yoga sequence, our hot Flow classes help to improve muscle tone and flexibility, prevent injury, decrease inflammation and lactic acid buildup, shed excess water weight and reduce edema, tonify the skin, and produce dramatic physical results in the least amount of time.`,
    image: UniqueClasses2,
    mobImage: UniqueClasses2Mob,
    classType: 'FLOW',
  },
];
const AppSpecList = [
  {
    index: 1,
    title: 'Here, You Are Always a VIP',
    description: `At Yoga Joint, we modeled our customer service after luxury brands. Our staff is trained to create a unified experience for our members and do everything possible to provide the highest level of customer service at all times. No matter how short the interaction with our team—you can expect an engaged and professional experience every time.
    `,
    image: YogaAppSpec1,
  },
  {
    index: 2,
    title: 'The Yoga Joint App',
    description: `The Yoga Joint app allows you to reserve your favorite mat space, access free guest passes, and connect with friends and family who are also part of the Yoga Joint community.
    `,
    image: YogaAppSpec2,
  },
  {
    index: 3,
    title: 'Free Online,\nOn-Demand Classes',
    description: `Members and non-members alike can enjoy our curated library of Flow and FIIT classes while traveling or from the comfort of home.\n\nJust download the Yoga Joint app to gain instant access to our online suite of classes and personalized pose library.
    `,
    image: YogaAppSpec3,
  },
];

const Stubs = {
  StudiosDescriptionList,
  UniqueClassesList,
  AppSpecList,
};

export default Stubs;
