/* eslint-disable no-nested-ternary */
import InputMask from 'react-input-mask';
import { primaryTheme } from 'theming';
import { chakra, Flex, Input, Text } from '@chakra-ui/react';
import { useField, FieldHookConfig } from 'formik';

type PrimaryInputProps = FieldHookConfig<string> & {
  label: string;
  placeholder?: string;
  mask?: string;
  isMasked?: boolean;
  isInRow?: boolean;
  isLabelSmall?: boolean;
};

const Wrapper = chakra(Flex, {
  baseStyle: {
    width: '100%',
    height: 'fit-content',
    flexDirection: 'column',
  },
});

const Label = chakra(Text, {
  baseStyle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    color: primaryTheme.colors.brand.white,
  },
});

const InputField = chakra(Input, {
  baseStyle: {
    opacity: 0.8,
    borderRadius: 0,
    marginTop: '8px',
    fontSize: '18px',
    paddingBottom: '8px',
    background: 'transparent',
    _focusVisible: { outline: 'none' },
    color: primaryTheme.colors.brand.white,
    borderBottom: `1px solid ${primaryTheme.colors.brand.white}`,
  },
});

const TextAreaField = chakra('textarea', {
  baseStyle: {
    opacity: 0.8,
    borderRadius: 0,
    marginTop: '8px',
    fontSize: '18px',
    background: 'transparent',
    _focusVisible: { outline: 'none' },
    color: primaryTheme.colors.brand.white,
    borderBottom: `1px solid ${primaryTheme.colors.brand.white}`,
    resize: 'vertical',
  },
});

const ErrorMessage = chakra(Text, {
  baseStyle: {
    marginTop: 1,
    height: '18px',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    color: primaryTheme.colors.red[500],
  },
});

const PrimaryInput = ({
  label,
  placeholder,
  mask = '999-999-9999',
  isMasked,
  isInRow = false,
  isLabelSmall = false,
  required = false,
  ...props
}: PrimaryInputProps) => {
  const [field, meta] = useField(props);
  const { type = '' } = props;

  return (
    <Wrapper width={isInRow ? '50%' : '100%'}>
      <Label fontSize={{ base: isLabelSmall ? '10px' : '12px', lg: '12px' }}>{label}</Label>
      {type !== 'textarea' && !isMasked ? (
        <InputField
          {...field}
          width="100%"
          type={type}
          variant="unstyled"
          placeholder={placeholder}
          required={required}
          _placeholder={{ fontSize: { base: isLabelSmall ? '14px' : '18px', lg: '18px' } }}
        />
      ) : type !== 'textarea' && isMasked ? (
        <InputField
          {...field}
          width="100%"
          mask={mask}
          as={InputMask}
          variant="unstyled"
          placeholder={placeholder}
          _placeholder={{ fontSize: { base: isLabelSmall ? '14px' : '18px', lg: '18px' } }}
          required={required}
        />
      ) : (
        <TextAreaField
          {...field}
          width="100%"
          placeholder={placeholder}
          required={required}
          _placeholder={{ fontSize: { base: isLabelSmall ? '14px' : '18px', lg: '18px' } }}
          rows={4}
        />
      )}
      <ErrorMessage>{meta.touched && meta.error ? meta.error : null}</ErrorMessage>
    </Wrapper>
  );
};

export default PrimaryInput;
