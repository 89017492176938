import { Box, chakra, Text, useMediaQuery } from '@chakra-ui/react';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';

const Container = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: { base: 'column', lgA: 'row' },
    gap: '151px',
    padding: { xlA: '0px !important' },
    maxWidth: { base: 'auto', xlA: '1254px' },
    justifyContent: { base: 'center', lgA: 'space-between' },
    alignItems: 'flex-end',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    marginLeft: { base: 'auto', md: 'unset' },
    marginRight: { base: 'auto', md: 'unset' },
    lineHeight: { base: '120%', md: '110%', xl: '130%' },
    background: 'linear-gradient(274.64deg, #547AFF 11.11%, #FF42DB 116.92%)',
    backgroundClip: 'text',
    textAlign: { base: 'center', lg: 'unset' },
    textTransform: 'capitalize',
    fontSize: { base: '35px', md: '42px', lg: '50px' },
    maxWidth: { base: '324px', md: '400px', lg: '470px' },
    fontWeight: '500',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    maxWidth: { lg: '600px' },
    marginLeft: { base: 'auto', md: 'unset' },
    marginRight: { base: 'auto', md: 'unset' },
    fontSize: { base: '18px', lg: '20px' },
    fontWeight: '300',
    lineHeight: '145%',
    letterSpacing: '0.045em',
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
    height: '100%',
    whiteSpace: 'pre-line',
  },
});

type TextContentProps = {
  title: string;
  description: string;
};

const TextContent = ({ title, description }: TextContentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 767px)');

  return (
    <Container ref={ref} opacity={enterCount >= 1 ? 1 : 0}>
      <Box>
        <Title
          animation={inViewport || enterCount ? AnimationFrame.slideRight : 'none'}
          paddingLeft={isMobileView ? '12px' : '0px'}
          paddingRight={isMobileView ? '12px' : '0px'}
        >
          {title}
        </Title>
      </Box>
      <Box>
        <Description animation={inViewport || enterCount ? AnimationFrame.slideLeft : 'none'}>{description}</Description>
      </Box>
    </Container>
  );
};

export default TextContent;
