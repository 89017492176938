import { chakra, Divider } from '@chakra-ui/react';
import { primaryTheme } from 'theming';
const CustomDivider = chakra(Divider, {
  baseStyle: {
    width: '100%',
    height: { base: '7px', lg: '10px' },
    backgroundImage: primaryTheme.colors.gradients.preSaleGradient,
    border: '1px solid transparent',
    boxSizing: 'border-box',
    opacity: 1,
  },
});

const TopBorder = () => {
  return <CustomDivider />;
};

export default TopBorder;
