const CTA_TEXT_DESKTOP = 'Reserve Your Lowest Rate Today!';
const CTA_TEXT_MOB = 'Reserve Your Lowest Rate!';

const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.yogajoint.mobile';
const APP_STORE_URL = 'https://apps.apple.com/us/app/shift-by-yoga-joint/id1553410096';

const Constant = {
  CTA_TEXT_DESKTOP,
  PLAY_STORE_URL,
  APP_STORE_URL,
  CTA_TEXT_MOB,
};

export default Constant;
