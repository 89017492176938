import { primaryTheme } from 'theming';
import { TransparentPlaceholder } from '../../assets';
import { AspectRatio, Box, chakra, Flex, Text, Image as ChakraImage } from '@chakra-ui/react';

const Image = chakra(ChakraImage, {
  baseStyle: {
    width: '100%',
  },
});

const Card = chakra(Box, {
  baseStyle: {
    marginBottom: '32px',
    height: 'fit-content',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontWeight: '600',
    lineHeight: '100%',
    fontSize: {
      sm: '26px',
      smAA: '32px',
      lg: '40px',
    },
    color: primaryTheme.colors.brand.white,
    textTransform: 'uppercase',
    zIndex: 100,
    marginTop: { sm: '-64px', smA: '-73px' },
    whiteSpace: 'pre-line',
    letterSpacing: { sm: '-1px', md: '2px' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontWeight: 300,
    fontSize: { base: '15px', md: '17px' },
    lineHeight: { base: '22px', lg: '24px' },
    color: primaryTheme.colors.brand.white,
    width: { base: '95%', md: '85%', lg: '75%' },
    margin: { base: '30px 0 20px 0', md: '40px 0' },
  },
});

interface CardContent {
  id: number;
  name: string;
  image: string;
  description: string;
}

interface StudioDescriptionCardProps {
  index: number;
  animate?: string;
  item: CardContent;
}

const StudioDescriptionCard = ({ index, item, animate }: StudioDescriptionCardProps) => {
  const isSecond = () => {
    return (index - 1) % 2 === 0;
  };

  const getMarginLeft = () => {
    if (index % 2 === 0) {
      return '0px';
    }
    return '73px';
  };

  const getMarginRight = () => {
    if (index % 2 === 1) {
      return '0px';
    }
    return '32px';
  };

  const getMarginTop = () => {
    if (index % 2 === 0) {
      return '-100px';
    }
    return '0';
  };

  return (
    <Card
      width="unset"
      animation={animate}
      key={item.id}
      marginRight={{ base: 0, md: '16px', xl: getMarginRight() }}
      marginLeft={{ base: 0, md: '16px', xl: getMarginLeft() }}
      marginBottom={{ base: '70px', lg: '125px' }}
      marginTop={{ md: !isSecond() ? '0px' : '80px', lg: getMarginTop(), xl: !isSecond() ? '-160px' : '0px' }}
    >
      <AspectRatio ratio={2} width="100%" overflow="hidden">
        <Image transitionDuration="300ms" src={item.image} fallbackSrc={TransparentPlaceholder} />
      </AspectRatio>
      <Flex
        flexDirection="column"
        justifyContent="flex-end"
        height={{ base: 'auto' }}
        background={primaryTheme.colors.gradients.reviewCardBg}
        padding={{ base: '40px 10px 0px 32px', md: '40px 25px 0px 51px' }}
      >
        <Title>{item.name}</Title>
        <Description>{item.description}</Description>
      </Flex>
    </Card>
  );
};

export default StudioDescriptionCard;
