import { keyframes } from '@chakra-ui/react';

const keyframeSlideRight = keyframes`
  from {
    opacity: 0.2;
    transform: translateX(-22px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
  `;

const keyframeSlideUpRight = keyframes`
  from {
    opacity: 0.2;
    transform: translate(-22px,22px);
  }
  to {
    opacity: 1;
    transform: translate(0px,0px);
  }
  `;

const keyframeSlideDownRight = keyframes`
  from {
    opacity: 0.2;
    transform: translate(-22px,-22px);
  }
  to {
    opacity: 1;
    transform: translate(0px,0px);
  }
  `;

const keyframeSlideUpLeft = keyframes`
  from {
    opacity: 0.2;
    transform: translate(22px,22px);
  }
  to {
    opacity: 1;
    transform: translate(0px,0px);
  }
  `;

const keyframeSlideDownLeft = keyframes`
  from {
    opacity: 0.2;
    transform: translate(22px,-22px);
  }
  to {
    opacity: 1;
    transform: translate(0px,0px);
  }
  `;

const keyframSlideHalfRight = keyframes`
  from {
    opacity: 0.2;
    transform: translateX(20%);
  }
  to {
    opacity: 1;
    transform: translateX(50%);
  }
  `;

const keyframeSlideLeft = keyframes`
  from {
    opacity: 0.2;
    transform: translateX(22px);

  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
  `;

const keyframeSlideHalfLeft = keyframes`
  from {
    opacity: 0.2;
    transform: translateX(-20%);

  }
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
  `;

const keyframeSlideUp = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(22px);

  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
  `;

const keyframeSlideUp2 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(20%);

  }
  to {
    opacity: 1;
    transform: translateY(-20%);
  }
  `;
const keyframeSlideUp3 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(20%);

  }
  to {
    opacity: 1;
    transform: translateY(-45%);
  }
  `;

const keyframeSlideUp55 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(20%);

  }
  to {
    opacity: 1;
    transform: translateY(-55%);
  }
  `;

const keyframeSlideDownCut1 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(-15%);

  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
  `;
const keyframeSlideDownCut2 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(0);

  }
  to {
    opacity: 1;
    transform: translateY(19%);
  }
  `;

const keyframeSlideDownCut3 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(0);

  }
  to {
    opacity: 1;
    transform: translateY(32%);
  }
  `;

const keyframeSlideDown = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(-22px);

  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
  `;

const keyframeSlideDown2 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(0px);

  }

  to {
    opacity: 1;
    transform: translateY(65%);
  }
  `;

const keyframeSlideDown3 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(-60%);

  }

  to {
    opacity: 1;
    transform: translateY(-20%);
  }
  `;

const keyframeSlideDown4 = keyframes`
  from {
    opacity: 0.2;
    transform: translateY(-60%);

  }

  to {
    opacity: 1;
    transform: translateY(-45%);
  }
  `;

const keyframeFade = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}`;

const keyframeFadeOut = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8) translateX(-30px);

  }
  to {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }
  `;

const keyframeInfoCard = keyframes`
  from {
    opacity: -2;
    transform: scale(0.8) translateX(-200px);

  }
  to {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
  `;

const keyframeScaleUp = keyframes`
  from {
    opacity: 0;
    transform: scale(0.7) ;

  }
  to {
    opacity: 1;
    transform: scale(1) ;
  }
  `;

const mobileSlideLeftKeyframes = keyframes`
from {
  opacity: 0.3;
  transform: translateX(360px);
}
to {
  opacity: 1;
  transform: translateX(0px);
}
`;

const CardSlideRightKeyframes = keyframes`
from {
  opacity: 0;
  transform: translateX(-360px);
}
to {
  opacity: 1;
  transform: translateX(0px);
}
`;

const ButtonScrollKeyframes = keyframes`
from {
  bottom:-5%;
  opacity:0;
}
to {
  bottom:'2%';
  opacity:1;
}
`;

const fadeInToPlace = keyframes`
from {
  opacity:0;
}
to {
  opacity:1;
}
`;

const fadeOutOfPlace = keyframes`
from {
  opacity:1;
}
to {
  opacity:0;
}
`;

const videoFadeIn = keyframes`
from {
  opacity:0;
  backgroundImage: linear-gradient(180deg, #ffffff 0%, rgba(1, 1, 1, 0) 100%);
}
to {
  opacity:1;
  backgroundImage: linear-gradient(180deg, #010101 0%, rgba(1, 1, 1, 0) 100%);
}
`;

const videoFadeOut = keyframes`
from {
  opacity:1;
  backgroundImage: linear-gradient(180deg, #010101 0%, rgba(1, 1, 1, 0) 100%);
}
to {
  opacity:0;
  backgroundImage: linear-gradient(180deg, #ffffff 0%, rgba(1, 1, 1, 0) 100%);
}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const zoomOut = keyframes`
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1.035);
  }
`;

const KeyFrameSubtleTopDown = keyframes`
   0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5%);
  }
`;
const KeyFrameSubtleTopDown2 = keyframes`
   0%,
  100% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(0%);
  }
`;
const KeyFrameSubtleTopDown3 = keyframes`
  0%,
  100% {
    transform: translateY(-7%);
  }
  50% {
    transform: translateY(0%);
  }
`;
const KeyFrameSubtleTopDown4 = keyframes`
   0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7%);
  }
`;
const keyframeSlideRightWithOpacity = keyframes`
  from {
    opacity: 0.5;
    transform: translateX(-22px);
  }
  to {
    opacity: 0.15;
    transform: translateX(0px);
  }
  `;

const SlideUp = keyframes`
from {
    transform: translateY(-100%) rotate(270deg);
    opacity: 0;
  }
  to {
    transform: translateY(0%) rotate(270deg);
    opacity: 1;
  }
`;
const KeyFrameSlideUpWithOpacity = keyframes`
from {
    transform: translateY(-100%) rotate(270deg);
    opacity: 0.5;
  }
  to {
    transform: translateY(0%) rotate(270deg);
    opacity: 0.15;
  }
`;

const videoFadeInAnimation = `${videoFadeIn}  0.5s ease-in-out`;
const videoFadeOutAnimation = `${videoFadeOut}  0.5s ease-in-out`;

const fadeInToPlaceAnimation = `${fadeInToPlace}  1s ease-in-out`;
const fadeOutOfPlaceAnimation = `${fadeOutOfPlace}  1s ease-in-out`;

const mobileAnimation = `${mobileSlideLeftKeyframes}  1s ease-in-out`;
const UpcomingAnimation = `${CardSlideRightKeyframes}  2s ease-in-out`;

const fade = `${keyframeFade}  1.5s ease-out`;
const fadeOut = `${keyframeFadeOut}  1.5s ease-out`;
const ScaleUp = `${keyframeScaleUp}  1.5s ease-out`;
const slideLeft = `${keyframeSlideLeft}  1.5s ease-out`;
const slideHalfLeft = `${keyframeSlideHalfLeft}  1.5s ease-out`;

const slideRight = `${keyframeSlideRight}  1.5s ease-out`;
const slideHalfRight = `${keyframSlideHalfRight}  1.5s ease-out`;

const slideUpRight = `${keyframeSlideUpRight}  1.5s ease-out`;
const slideDownRight = `${keyframeSlideDownRight}  1.5s ease-out`;
const slideUpLeft = `${keyframeSlideUpLeft}  1.5s ease-out`;
const slideDownLeft = `${keyframeSlideDownLeft}  1.5s ease-out`;

const slideUp = `${keyframeSlideUp}  1.5s ease-out`;
const slideUp2 = `${keyframeSlideUp2}  2s ease-out`;
const slideUp3 = `${keyframeSlideUp3}  2s ease-out`;
const slideUp55 = `${keyframeSlideUp55}  2s ease-out`;

const slideDown = `${keyframeSlideDown}  1.5s ease-out`;
const slideDown2 = `${keyframeSlideDown2}  1.5s ease-out`;
const slideDown3 = `${keyframeSlideDown3}  2s ease-out`;
const slideDown4 = `${keyframeSlideDown4}  2s ease-out`;

const ButtonScrollAnimated = `${ButtonScrollKeyframes}  2s ease-out`;
const blink = `${fadeInToPlace} 1.5s infinite`;
const SubtleTopDown = `${KeyFrameSubtleTopDown} 1.5s ease-out infinite`;
const SubtleTopDown2 = `${KeyFrameSubtleTopDown2} 2s ease-out infinite`;
const SubtleTopDown3 = `${KeyFrameSubtleTopDown3} 2.5s ease-out infinite`;
const SubtleTopDown4 = `${KeyFrameSubtleTopDown4} 3s ease-out infinite`;
const SlideRightWithOpacity = `${keyframeSlideRightWithOpacity} 2.5s ease-out`;
const SlideUp270 = `${SlideUp} 2.5s ease-out`;
const SlideUpWithOpacity = `${KeyFrameSlideUpWithOpacity} 2.5s ease-out`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  keyframeFadeOut,
  keyframeInfoCard,
  slideLeft,
  slideRight,
  fadeOut,
  slideUp,
  ScaleUp,
  slideUp2,
  slideUp3,
  slideUp55,
  slideHalfLeft,
  slideHalfRight,
  slideDown,
  slideDown2,
  slideDown3,
  slideDown4,
  mobileAnimation,
  UpcomingAnimation,
  ButtonScrollAnimated,
  slideUpLeft,
  slideUpRight,
  slideDownLeft,
  slideDownRight,
  fade,
  keyframeSlideDownCut1,
  keyframeSlideDownCut2,
  keyframeSlideDownCut3,
  fadeInToPlaceAnimation,
  fadeOutOfPlaceAnimation,
  videoFadeInAnimation,
  videoFadeOutAnimation,
  fadeIn,
  zoomOut,
  blink,
  SubtleTopDown,
  SubtleTopDown2,
  SubtleTopDown3,
  SubtleTopDown4,
  SlideRightWithOpacity,
  SlideUp270,
  SlideUpWithOpacity,
};
