import { OptionsRouter } from 'react-typesafe-routes';
import { PreSalePage, GetAppPage, FormPage } from 'pages';

const defaultOptions = {
  isHomePage: false,
  isMailer: false,
  isAd: false,
};

const router = OptionsRouter(defaultOptions, (route) => ({
  root: route('/', {
    component: () => {
      window.location.href = '/sale';
      return null;
    },
    options: {
      isHomePage: false,
    },
  }),
  sale: route('sale', {
    component: PreSalePage,
  }),
  formPage: route('form', {
    component: FormPage,
  }),
  getApp: route('get-app', {
    component: GetAppPage,
  }),
}));

export default router;
