import animationFrames from 'utils/animationFrames';
import { primaryTheme } from 'theming';
import { useEffect, useState, useCallback } from 'react';
import { chakra, Text, Flex, useMediaQuery, Image } from '@chakra-ui/react';
import { PromoBarImageFull } from 'assets';
import { Link } from 'react-router-dom';

const Container = chakra(Flex, {
  baseStyle: {
    background: primaryTheme.colors.brand.lemonYellow,
    top: 0,
    right: 0,
    left: 0,
    zIndex: 200,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const PromoBarText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '17px', lg: '22px' },
    fontWeight: '700',
    lineHeight: { base: '23px', md: '100%' },
    color: primaryTheme.colors.brand.cyprus,
    maxWidth: { base: '360px', md: 'unset' },
    margin: { base: 'auto', md: 'unset' },
    letterSpacing: '1px',
    span: {
      color: primaryTheme.colors.brand.themePurple,
    },
  },
});

type PromoBarProps = {
  isPromoClosed: boolean;
  onClick?: () => void;
};

let timer: NodeJS.Timeout;
const PromoBar = ({ isPromoClosed, onClick }: PromoBarProps) => {
  const [showPromoBar, setPromoBarShow] = useState<boolean>(false);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const setShowPromoBar = useCallback(
    (val: boolean) => {
      setPromoBarShow(val);
    },
    [setPromoBarShow]
  );

  useEffect(() => {
    const controlPromoVisibilty = () => {
      if (!isPromoClosed && (window.scrollY > lastScrollY + window.innerHeight || window.scrollY < lastScrollY - 100)) {
        if (window.scrollY <= 0) {
          setShowPromoBar(false);
        } else if (Math.floor(window.scrollY) === Math.floor(window.screenTop)) {
          setShowPromoBar(false);
        } else if (Math.floor(window.scrollY) > lastScrollY) {
          setShowPromoBar(true);
        } else if (Math.floor(window.scrollY) < lastScrollY) {
          setShowPromoBar(false);
        }
        timer = setTimeout(() => {
          setLastScrollY(Math.floor(window.scrollY));
        }, 300);
      }
    };

    window.addEventListener('scroll', controlPromoVisibilty);
    return () => {
      window.removeEventListener('scroll', controlPromoVisibilty);
      clearTimeout(timer);
    };
  }, [lastScrollY, isPromoClosed, setShowPromoBar, showPromoBar]);

  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  const promoText = isMobileView
    ? 'Reserve Your <span>LOWEST RATE TODAY!</span>'
    : 'Click Here To Reserve Your <span>LOWEST RATE TODAY!</span>';
  return (
    <Link to="/form" onClick={onClick}>
      <Container
        position="fixed"
        overflow="hidden"
        transition="height 0.5s ease-in-out"
        height={{ base: showPromoBar ? '53px' : '0px', lg: showPromoBar ? '64px' : '0px' }}
      >
        <Flex
          flexDir={isMobileView ? 'column' : undefined}
          animation={showPromoBar ? animationFrames.fadeInToPlaceAnimation : animationFrames.fadeOutOfPlaceAnimation}
          background={primaryTheme.colors.brand.lemonYellow}
          width="100%"
          textAlign="center"
          justifyContent="center"
          cursor="pointer"
          alignItems="center"
          gap="10px"
        >
          {!isMobileView && <Image height="100%" src={PromoBarImageFull} />}

          <PromoBarText
            dangerouslySetInnerHTML={{
              __html: promoText,
            }}
            letterSpacing={{ xs: '-1px', smA: 'unset' }}
          ></PromoBarText>
        </Flex>
      </Container>
    </Link>
  );
};

export default PromoBar;
