import { Box, Button, chakra, Divider, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { ShiftIconFive, ShiftIconOne, ShiftIconTwo, YjLogoWithBg, YogaJointLogoWithText } from 'assets';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { Link } from 'react-router-dom';
import { primaryTheme } from 'theming';
import { AnimationFrame, Constant, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      base: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(${ShiftIconFive}), url(${ShiftIconTwo}), url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: { base: '4% 100%, 30% 100%, 0% -10% !important', '3xlB': '35% 100%, 40% 100%, 20% -10% !important' },
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    backgroundSize: { base: 'cover', md: '100% 100%' },
    gap: { lg: '40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: { base: 'column', lg: 'row' },
  },
});

const LeftContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
  },
});

const RightContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '58px',
    width: { lg: '40%' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '40px', xl: '70px' },
    fontWeight: '500',
    lineHeight: { base: '48px', xl: '77px' },
    whiteSpace: 'pre-line',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    textAlign: { base: 'center', lg: 'unset' },
    maxWidth: { lg: '470px' },
    margin: { base: 'auto', lg: 'unset' },
    marginBottom: { lg: '30px' },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: '20px',
    fontWeight: '300',
    lineHeight: '29px',
    color: primaryTheme.colors.brand.white,
    textAlign: { base: 'center', lg: 'unset' },
    maxWidth: { base: '500px', lg: '579px' },
    margin: { base: 'auto', lg: 'unset' },
    marginTop: { lg: '20px' },
    letterSpacing: '1px',
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.gradients.preSaleGradient,
    width: { base: '85px', lg: '112px' },
    height: { base: '5px', lg: '10px' },
    border: '0.5px solid transparent',
    opacity: '1',
    bgRepeat: 'no-repeat',
    margin: { base: '40px 0 0 0', lg: ' 0 0 10px 0' },
    display: 'flex',
    alignSelf: { base: 'center', lg: 'unset' },
  },
});

const MethodText = chakra(Text, {
  baseStyle: {
    fontSize: '26px',
    fontWeight: '600',
    letterSpacing: '4.5%',
    color: primaryTheme.colors.brand.white,
    textAlign: 'center',
    marginTop: { base: '-15px', lg: '-30px !important' },
    marginLeft: { base: '-15px', lg: 'unset' },
  },
});

type TransformSectionProps = {
  onClick: () => void;
};

const TransformSection = ({ onClick }: TransformSectionProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  return (
    <OuterContainer
      padding={{ lg: '123px 0 100px' }}
      paddingLeft={!isMobileView ? margin : ''}
      paddingRight={!isMobileView ? margin : ''}
    >
      <Container
        paddingLeft={isMobileView ? margin : ''}
        paddingRight={isMobileView ? margin : ''}
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
      >
        {isMobileView && (
          <Flex flexDirection="column" justifyContent="center">
            <YogaJointLogoWithText />
            <MethodText>Method</MethodText>
          </Flex>
        )}
        <LeftContainer>
          <CustomDivider />
          <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>What Is The Yoga Joint Experience?</Title>

          <SubTitle animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
            The Yoga Joint Method is about much more than giving you the best workout of your life (although we’ll do that, too!);
            our Flow and FIIT classes give you the skills you need to develop a strong, centered mind, become less reactive to
            everyday stressors, and—as a result—grow able to handle whatever comes your way with confidence and poise.
          </SubTitle>
          <SubTitle
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            marginTop={{ base: '10px', lg: 'unset' }}
          >
            <strong> If life is about balance, think of us as your counterweight!</strong>
          </SubTitle>
          {isMobileView && (
            <Link to="/form" onClick={onClick}>
              <Button
                variant="primary"
                size="primary"
                width={{ base: '100%', md: '403px' }}
                height="56px"
                _focus={{ outline: '0 !important' }}
                fontWeight="700"
                fontSize="16px"
                margin={{ base: '29px auto 0', xl: '10px 0 0' }}
                animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
                cursor="pointer"
                alignSelf="center"
                display="flex"
                whiteSpace="pre-line"
                letterSpacing={{ base: '-0.7px', md: 'unset' }}
              >
                {Constant.CTA_TEXT_DESKTOP}
              </Button>
            </Link>
          )}
        </LeftContainer>
        {!isMobileView && (
          <RightContainer>
            <Image
              animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
              src={YjLogoWithBg}
              objectFit="contain"
              maxWidth={{ md: '500px', xl: '535px' }}
            />
            <Link to="/form" onClick={onClick}>
              <Button
                variant="primary"
                size="primary"
                width={{ base: '100%', md: '368px' }}
                height="64px"
                _focus={{ outline: '0 !important' }}
                fontWeight="700"
                fontSize="16px"
                margin={{ base: '39px auto 0', xl: '10px 30px 59px' }}
                animation={AnimationFrame.fadeOut}
                cursor="pointer"
                onClick={onClick}
                whiteSpace="pre-line"
              >
                {Constant.CTA_TEXT_DESKTOP}
              </Button>
            </Link>
          </RightContainer>
        )}
      </Container>
    </OuterContainer>
  );
};

export default TransformSection;
