export const submitForm = ({
  email,
  firstname,
  lastname,
  phone,
  zipcode,
  url,
}: {
  firstname: string;
  lastname: string;
  phone: string;
  zipcode: string;
  email: string;
  url: string;
}) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const data = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstname || '',
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastname || '',
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'phone',
          value: phone,
        },
        {
          objectTypeId: '0-1',
          name: 'zip_code',
          value: zipcode,
        },
      ],
      context: {
        pageUri: window.location.hostname,
        pageName: 'YJ WEB',
      },
    };
    const finalData = JSON.stringify(data);

    xhr.open('POST', url);

    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(true);
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        reject(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        reject(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        reject(xhr.responseText);
      }
    };

    xhr.send(finalData);
  });
