import { chakra, Flex, Text } from '@chakra-ui/react';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';

const OuterContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '20px', lg: '35px' },
    fontWeight: '600',
    lineHeight: { base: '24px', lg: '45px' },
    textAlign: 'center',
    backgroundClip: 'text',
    bgGradient: primaryTheme.colors.gradients.preSaleGradient,
    whiteSpace: 'pre-line',
    margin: 'auto',
    textTransform: 'capitalize',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '33px', lg: '58px' },
    lineHeight: { base: '44px', lg: '75px' },
    fontWeight: '400',
    color: primaryTheme.colors.brand.white,
    margin: 'auto',
    textAlign: 'center',
    whiteSpace: 'pre-line',
    textTransform: 'capitalize',
  },
});

type TextContentColumnProps = {
  title: string;
  description: string;
  isRegisterPage?: boolean;
  isSchedulePage?: boolean;
  path?: string;
};

const TextContentColumn = ({ title, description, isRegisterPage, isSchedulePage, path }: TextContentColumnProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);

  return (
    <OuterContainer
      ref={ref}
      opacity={enterCount >= 1 ? 1 : 0}
      animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
      gap={{ base: '15px', lg: isRegisterPage ? '28px' : isSchedulePage ? '22px' : '15px' }}
      width="100%"
    >
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        textTransform={isSchedulePage || path === '/enrol' ? 'unset' : undefined}
        fontSize={{
          base: isRegisterPage ? (path === '/enrol' ? '2em' : '40px') : isSchedulePage ? '26px' : '20px',
          lg: '31px',
        }}
        fontWeight="600"
        lineHeight={{
          base: isRegisterPage ? (path === '/enrol' ? '40px' : '46px') : isSchedulePage ? '30px' : '24px',
          lg: '35px',
        }}
        maxWidth={{ base: '300px', lg: '970px' }}
      />
      <Description
        dangerouslySetInnerHTML={{
          __html: description,
        }}
        textTransform={isSchedulePage ? 'unset' : undefined}
        fontSize={{
          base: isRegisterPage ? (path === '/enrol' ? '18px' : '20px') : isSchedulePage ? '14px' : '26px',
          lg: '45px',
        }}
        fontWeight={isSchedulePage ? 'medium' : '400'}
        lineHeight={{
          base: isRegisterPage ? (path === '/enrol' ? '23px' : '26px') : isSchedulePage ? '18px' : '32px',
          lg: '50px',
        }}
        maxWidth={{
          base: '320px',
          lg: path === '/enrol' ? '788px' : path === '/grandopening' ? 'auto' : '1006px',
        }}
        letterSpacing="-1px"
      />
    </OuterContainer>
  );
};

export default TextContentColumn;
