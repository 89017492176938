import * as yup from 'yup';

export const preSaleForm = yup.object({
  email: yup.string().default('').email('Enter a valid email').required('Email is required'),
  firstname: yup.string().default('').required('Name is required').min(2, 'Name is too short').max(50, 'Name is too long'),
  lastname: yup.string().default('').required('Name is required').min(2, 'Name is too short').max(50, 'Name is too long'),
  phone: yup
    .string()
    .default('')
    .required('Telephone Number is required')
    .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Telephone Number is invalid'),
  zipcode: yup
    .string()
    .required('Zip Code is required')
    .default('')
    .matches(/^\d{5}$/, 'Zip Code is invalid'),
});

const yupSchemas = {
  preSaleForm,
};

export default yupSchemas;
