import { Box } from '@chakra-ui/react';

const AppStoreIcon = () => {
  return (
    <Box as="svg" width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6833 11.1574C14.7036 9.58031 15.5505 8.08805 16.8941 7.26202C16.0465 6.05147 14.6268 5.28395 13.1497 5.23774C11.5743 5.07238 10.047 6.18045 9.24419 6.18045C8.42585 6.18045 7.1898 5.25416 5.85878 5.28154C4.12386 5.33759 2.50647 6.32398 1.6624 7.84076C-0.152037 10.9822 1.20137 15.599 2.93944 18.1383C3.80904 19.3817 4.82534 20.7706 6.15516 20.7213C7.45647 20.6674 7.94248 19.8915 9.51327 19.8915C11.0695 19.8915 11.5255 20.7213 12.8823 20.69C14.2786 20.6674 15.1584 19.4411 15.9975 18.1859C16.6223 17.2999 17.1031 16.3207 17.422 15.2846C15.781 14.5905 14.6852 12.9391 14.6833 11.1574Z"
        fill="black"
      />
      <path
        d="M12.1205 3.56779C12.8818 2.65381 13.2569 1.47904 13.1661 0.292969C12.0029 0.415139 10.9285 0.971063 10.1568 1.84998C9.40227 2.70872 9.00959 3.86281 9.08383 5.00355C10.2474 5.01553 11.3913 4.47468 12.1205 3.56779Z"
        fill="black"
      />
    </Box>
  );
};

export default AppStoreIcon;
