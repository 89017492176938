import { chakra, Flex, Text, Button, useMediaQuery } from '@chakra-ui/react';
import { PrimaryInput } from 'components';
import { AnimationFrame, Constant, preSaleForm, submitForm } from 'utils';
import { primaryTheme } from 'theming';
import { useRef } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

const HighLightText = chakra(Text, {
  baseStyle: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '29px',
    color: primaryTheme.colors.brand.lemonYellow,
    textAlign: { base: 'center', lg: 'unset' },
    maxWidth: { base: '311px', md: 'unset' },
    margin: { base: 'auto', lg: 'unset' },
  },
});

const FieldContainer = chakra(Flex, {
  baseStyle: {
    paddingTop: { xl: '20px' },
    flexDirection: 'column',
  },
});

const FieldRow = chakra(Flex, {
  baseStyle: {
    flexDirection: { base: 'column', lg: 'row' },
    gap: { xl: '40px' },
    width: '100%',
  },
});

const FormFields = () => {
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const url = 'https://api.hsforms.com/submissions/v3/integration/submit/21517867/6ff6eef6-3ca3-4470-8661-20855ff97a14';
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  return (
    <Formik
      validationSchema={preSaleForm}
      initialValues={preSaleForm.getDefault()}
      onSubmit={async (values) => {
        await submitForm({
          email: values.email,
          firstname: values.firstname,
          lastname: values.lastname,
          phone: values.phone,
          zipcode: values.zipcode,
          url,
        });
        history.push({
          pathname: '/get-app',
        });
      }}
    >
      {() => (
        <Form>
          <FieldContainer ref={ref}>
            <FieldRow animation={AnimationFrame.fadeOut}>
              <PrimaryInput name="firstname" label="First Name *" type="text" placeholder="First Name" required />
              {isMobileView ? (
                <PrimaryInput name="lastname" label="Last Name *" type="text" placeholder="Last Name" required />
              ) : (
                <PrimaryInput name="email" label="Email *" type="email" placeholder="Email Address" required />
              )}
            </FieldRow>
            <FieldRow animation={AnimationFrame.fadeOut}>
              {isMobileView ? (
                <>
                  <PrimaryInput name="phone" label="Phone *" type="text" placeholder="Phone" isMasked required />
                  <PrimaryInput name="email" label="Email *" type="email" placeholder="Email Address" required />
                </>
              ) : (
                <PrimaryInput name="lastname" label="Last Name *" type="text" placeholder="Last Name" required />
              )}
              <PrimaryInput name="zipcode" label="Zip Code *" type="text" placeholder="Zip Code" required />
            </FieldRow>
            <FieldRow width={{ lg: '45%' }} animation={AnimationFrame.fadeOut}>
              {!isMobileView && <PrimaryInput name="phone" label="Phone *" type="text" placeholder="Phone" isMasked required />}
            </FieldRow>

            <FieldRow
              flexDirection={{ base: 'column-reverse', lg: 'column' }}
              marginTop={{ xl: '54px' }}
              animation={AnimationFrame.fadeOut}
              justifyContent="space-between"
            >
              <HighLightText>This is a limited-time offer. Lock in this rate now before it’s gone!</HighLightText>
              <Button
                variant="primary"
                size="primary"
                width={{ base: '100%', lg: '332px' }}
                _focus={{ outline: '0 !important' }}
                fontSize="14px"
                letterSpacing={{ sm: '-0.1px', smAA: 'unset' }}
                margin={{ base: '20px auto 20px', lg: '30px 0 0' }}
                animation={AnimationFrame.fadeOut}
                type="submit"
                alignSelf="left"
              >
                {Constant.CTA_TEXT_MOB}
              </Button>
              <input type="hidden" name="reflink" value="" />
            </FieldRow>
          </FieldContainer>
        </Form>
      )}
    </Formik>
  );
};

export default FormFields;
