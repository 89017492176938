import { ButtonProps, chakra, Flex, Link, Text } from '@chakra-ui/react';
import { primaryTheme } from 'theming';

interface AppStoreButtonProps extends ButtonProps {
  icon: JSX.Element;
  title: string;
  subTitle: string;
  route: string;
}

const ButtonContainer = chakra(Flex, {
  baseStyle: {
    height: '44px',
    borderRadius: '168px',
    fontFamily: 'Montserrat',
    background: primaryTheme.colors.button.primary,
    border: `2px solid ${primaryTheme.colors.brand.salomie}`,
    boxShadow: 'inset 0px 0px 12px rgba(255, 255, 255, 0.4)',
    _hover: {
      cursor: 'pointer',
      background: primaryTheme.colors.brand.yellowHover,
    },
    whiteSpace: { base: 'pre-line', md: 'unset' },
    width: '167px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
const Title = chakra(Text, {
  baseStyle: {
    fontSize: '16px',
    fontWeight: '600',
    color: primaryTheme.colors.brand.black,
    textAlign: 'left',
    lineHeight: '18px',
  },
});
const SubTitle = chakra(Text, {
  baseStyle: {
    color: primaryTheme.colors.brand.black,
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '8px',
    textAlign: 'left',
  },
});

const AppStoreButton = ({ icon, title, subTitle, route }: AppStoreButtonProps) => {
  return (
    <Link href={route} target="_blank">
      <ButtonContainer>
        {icon}
        <Flex marginLeft="6px" justifyContent="center" flexDirection="column">
          <SubTitle>{subTitle}</SubTitle>
          <Title>{title}</Title>
        </Flex>
      </ButtonContainer>
    </Link>
  );
};

export default AppStoreButton;
