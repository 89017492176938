import { Box, Button, chakra, useMediaQuery } from '@chakra-ui/react';
import { BackGroundTwo, ShiftIconOne, ShiftIconSix, ShiftIconTwo } from 'assets';
import { YjAppSpec } from 'components';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { Link } from 'react-router-dom';
import { AnimationFrame, Constant, Stub } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    paddingTop: { md: '100px', xl: '400px' },
    marginTop: { base: '95px', lg: 'unset' },
    background: {
      md: `url(${BackGroundTwo})`,
    },
    backgroundSize: { base: 'cover', md: '100% 30%' },
    backgroundRepeat: 'repeat-y !important',
  },
});

const Container = chakra(Box, {
  baseStyle: {
    background: {
      md: `
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${ShiftIconOne}), url(${ShiftIconSix}),url(${ShiftIconTwo}), url(${ShiftIconOne})`,
    },
    backgroundPosition: {
      md: '45% 50%, 0% 0% , 0% 0%, 60% 30% !important',
      '3xlB': '40% 50%, 0% 0% , 0% 0%, 60% 30% !important',
    },
    backgroundRepeat: 'no-repeat !important',
  },
});

type AppSectionProps = {
  onClick: () => void;
};
const AppSection = ({ onClick }: AppSectionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.2 });
  const [isMobileView] = useMediaQuery('(max-width: 800px)');
  return (
    <Container>
      <OuterContainer>
        {Stub.AppSpecList.map((item) => (
          <YjAppSpec index={item.index} title={item.title} description={item.description} image={item.image} onClick={onClick} />
        ))}
        {!isMobileView && (
          <Link to="/form" onClick={onClick}>
            <Button
              variant="primary"
              size="primary"
              width={{ base: '90%', md: '446px' }}
              height="56px"
              _focus={{ outline: '0 !important' }}
              fontWeight="700"
              fontSize="16px"
              margin={{ base: '39px auto 0', xl: '10px auto 0' }}
              animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            >
              {Constant.CTA_TEXT_DESKTOP}
            </Button>
          </Link>
        )}
      </OuterContainer>
    </Container>
  );
};

export default AppSection;
