import { chakra, Flex, Text, Image, useMediaQuery, Box } from '@chakra-ui/react';
import { WestBocaMapStudio, WestBocaMapStudioMob } from 'assets';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';

const Title = chakra(Text, {
  baseStyle: {
    lineHeight: { base: '40px', lg: '110%' },
    textAlign: 'center',
    color: primaryTheme.colors.brand.white,
    whiteSpace: 'pre-line',
    maxWidth: { base: '80%', md: '747px' },
    fontSize: { base: '30px', lg: '40px' },
    fontWeight: '500',
  },
});

const LocationImageComp = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const [isMobileView] = useMediaQuery('(max-width: 800px)');

  return (
    <Box ref={ref}>
      <Image
        src={isMobileView ? WestBocaMapStudioMob : WestBocaMapStudio}
        animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
        width={{ md: '80%', lg: '100%' }}
        margin="auto"
      />
      <Flex marginTop={{ base: '64px', lg: '134px' }} flexDirection="column" alignItems="center">
        <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>Yoga Joint West Boca</Title>
        <Title
          fontSize={{ base: '20px', lg: '24px' }}
          lineHeight={{ base: '30px', lg: '45px' }}
          fontWeight="300"
          marginTop="6px"
          animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
        >
          8903 Glades Road West Boca, FL 33434
        </Title>
        <Title
          fontSize={{ base: '17px', lg: '19px' }}
          lineHeight="32px"
          fontWeight="300"
          letterSpacing="8px"
          marginTop="12px"
          color="#A4A8D4"
          animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
        >
          OPENING SOON IN 2024!
        </Title>
      </Flex>
    </Box>
  );
};

export default LocationImageComp;
