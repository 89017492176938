import { Box, Button, chakra, Divider, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { ShiftIconFive, ShiftIconOne, ShiftIconSix, WestBocaSwag, WestBocaSwagMob } from 'assets';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, Constant, useNavBarDimensions } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: {
      lg: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url(${ShiftIconFive}), url(${ShiftIconSix}), url(${ShiftIconSix}), url(${ShiftIconOne})`,
    },
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: {
      lg: '130% -23%, 70% 20%, 0% 0%, 0% 80% !important',
      xl: '110% -23%, 70% 40%, 10% 100%, 90% 70% !important',
      '3xlB': '90% 0%, 60% 35%, 20% 80%, 10% 20% !important',
    },
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    margin: 'auto',
    maxWidth: { base: 'auto', xlA: '1240px' },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: { base: '108px', lg: '263px' },
    gap: '30px',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontSize: { base: '30px', lg: '40px' },
    fontWeight: '500',
    lineHeight: { base: '36px', lg: '52px' },
    background: 'linear-gradient(274.64deg, #547AFF 11.11%, #FF42DB 116.92%)',
    backgroundClip: 'text',
    textAlign: 'center',
    letterSpacing: '-1px',
    whiteSpace: 'pre-line',
    maxWidth: { lg: '856px' },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontSize: { base: '20', lg: '22px' },
    fontWeight: '300',
    lineHeight: { base: '28px', lg: '31px' },
    color: primaryTheme.colors.brand.white,
    textAlign: 'center',
    maxWidth: { base: '354px', lg: '635px' },
    letterSpacing: { base: '-0.9px', md: 'unset' },
  },
});

const HighLightText = chakra(Text, {
  baseStyle: {
    fontSize: { base: '14px', lg: '20px' },
    fontWeight: '600',
    lineHeight: { base: '21px', lg: '33px' },
    color: primaryTheme.colors.brand.lemonYellow,
    textAlign: { base: 'center', lg: 'unset' },
    maxWidth: { base: '200px', lg: '445px' },
    marginTop: { lg: '43px' },
  },
});

const CustomDivider = chakra(Divider, {
  baseStyle: {
    background: primaryTheme.colors.gradients.preSaleGradient,
    width: '112px',
    height: '8px',
    border: '0.5px solid transparent',
    opacity: '1',
    bgRepeat: 'no-repeat',
    margin: { base: '0 auto 5px', lg: '0 0 5px' },
  },
});

type SwagSectionProps = {
  onClick?: () => void;
};

const SwagSection = ({ onClick }: SwagSectionProps) => {
  const { margin } = useNavBarDimensions();
  const [isMobileView] = useMediaQuery('(max-width: 786px)');
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.2 });

  return (
    <>
      <OuterContainer paddingLeft={!isMobileView ? margin : ''} paddingRight={!isMobileView ? margin : ''}>
        <Container
          ref={ref}
          opacity={enterCount >= 1 ? 1 : 0}
          paddingLeft={isMobileView ? margin : ''}
          paddingRight={isMobileView ? margin : ''}
        >
          <CustomDivider />
          <Title animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
            If Swag Is What You Love, Then Look No Further Because We've Got You Covered!{' '}
          </Title>
          <SubTitle animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
            As One of Our First 200 Newcomers, You Will Receive a Branded, Limited-Edition Yoga Hat!
          </SubTitle>
          <HighLightText animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}>
            Hurry! This is a limited-time offer only!
          </HighLightText>
          <Image
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            src={isMobileView ? WestBocaSwagMob : WestBocaSwag}
            marginTop={{ base: '20px', lg: '42px' }}
          />
          <Button
            variant="primary"
            size="primary"
            width={{ base: '90%', md: '360px' }}
            margin={{ base: '39px auto 30px', xl: '39px auto 0' }}
            animation={inViewport || enterCount ? AnimationFrame.fadeOut : 'none'}
            onClick={onClick}
          >
            {isMobileView ? Constant.CTA_TEXT_MOB : Constant.CTA_TEXT_DESKTOP}
          </Button>
        </Container>
      </OuterContainer>
    </>
  );
};

export default SwagSection;
